import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types' 
import { graphql, Link }  from 'gatsby'
import striptags from 'striptags'
import ArrowRight from '@material-ui/icons/ArrowForward'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import ActiveSearch from '../components/ActiveSearch'
import Text from '../components/Text'
import { normalizeDate } from '../utils/helpers';

import Div from './../components/Div'
import Hr from '../components/Hr'
import Article from '../components/Articles'
import { btnMedium_ } from '../components/styles/OutlineButton.styles'
import { postSummary, readMoreButton } from '../components/styles/PostBlog.styles'
import { DOMParser } from 'xmldom'


export const PageTemplate = ({
  data,
}) => {
  const [postLimit, setPostLimit] = useState(3);
  const [search, setSearch] = useState('');

  const searchQuery = new RegExp(search, 'i')

  

  useEffect(() => {

    setPostLimit(3)
  }, [search])

  const posts = search
    ? data.allWordpressPost.nodes.filter(({ title }) => title.match(searchQuery))
    : data.allWordpressPost.nodes

  const normalizeExcerpt = excerpt => {
    const stripped = striptags(excerpt)
    return stripped
      .replace(/<p class="link-more.*/, '')
      .replace(' […]', '')
      .slice(0, 400)
      .concat('...')
  }
  
  return (
    <section>
      <Div
        px={[16, 16, 24, 24]}
        alignItems={['center']}
      >
        <Div
          maxWidth={['100%', 390, 728]}
        >
          <Row
            pt={[50, 70]}
            pl={[6, 6, 0, 0]}
            pr={[6, 6, 0, 0]}
          >
            <Div
              maxWidth={['100%', 390, 728]}
            >
              <ActiveSearch 
                mx={[null]}
                onChange={(q) => {
                  setSearch(q)
                }}
              />
            </Div>
          </Row>
        </Div>
        {posts.length > 0 ? (
          <>
            <Div
              maxWidth={['100%', 390, 855, 1086]}
            >
              <Row
                flexDirection={['column']}
                mb={[30]}
                mt={[30, 60]}
                mx={[-16, -16, 0, 0]}
                pl={[0]}
                pr={[0]}
                alignItems={['center']}
                style={{
                  margin: '0 auto'
                }}
              >
                <Column
                  width={[1]}
                >
                  <Div
                    alignItems={['flex-start']}
                    textAlign={['left']}
                  >
                    <Div
                      maxWidth={[290, '100%', 680]}
                      mb={[5]}
                    >
                      <Link
                        to={`/blog/${posts[0].slug}`}
                        style={{
                          color: 'inherit'
                        }}
                      >
                        <Text
                          fontSize={[33.6]}
                          style={{
                            fontWeight: 'normal',
                            color: '#44474e',
                            letterSpacing: 0.25
                          }}
                          mt={[6]}
                          mb={[3]}
                          dangerouslySetInnerHTML={{
                            __html:  posts[0].title
                          }}
                        />
                      </Link>
                      <Div
                        style={{
                          fontSize: 11.8,
                          fontWeight: 600,
                          lineHeight: 1.36,
                          letterSpacing: 2,
                          color: 'rgba(0, 0, 0, 0.6)',
                          textTransform: 'uppercase'
                        }}
                      >
                        {normalizeDate(posts[0].date)} • Leitura 5 min
                      </Div>
                    </Div>
                    <Div
                      display={['block']}
                      maxWidth={['100%', 680]}
                      mb={[4]}
                    >
                      {posts[0].featured_media
                      ? (
                        <img
                          src={posts[0].featured_media.localFile.publicURL}
                          style={{
                            width: '100%',
                            height: 'auto',
                            flexShrink: 0,
                            borderRadius: 6
                          }}
                        />
                      )
                      : (
                        <div style={{ width: '100%', height: '100%', backgroundColor: 'rgb(243, 245, 245)' }} />
                      )}
                    </Div>
                    <Div
                      maxWidth={[290, '100%', 680]}
                      mb={[6]}
                    >
                      <Div
                        css={postSummary}
                        dangerouslySetInnerHTML={{
                          __html: normalizeExcerpt (posts[0].content)
                        }}
                      />
                      <Link
                        to={`/blog/${posts[0].slug}`}
                        css={readMoreButton}
                      >
                        Continue lendo
                        <ArrowRight 
                          style={{
                            color: '##9013fe',
                            marginLeft: 8,
                            width: 12
                          }}
                        />
                      </Link>
                    </Div>
                  </Div>
                </Column>
              </Row>
            </Div>
            <Hr
              lineColor='lightPeriwinkle'
              maxWidth={['100%', 422, 680, 680]}
              style={{
                opacity: 0.5
              }}
            />
            <Div
              maxWidth={['100%', 390, 680]}
              alignItems={['center', 'center', 'stretch']}
            >
              <Row
                pb={[50]}
                mx={[-16, -16, -24, -24]}
              >
                <Column
                  width={[1]}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  {posts.length > 0
                    && Array(Math.ceil(posts.length / 2)).slice(0, postLimit).fill(0).map((item, i) => {
                      return (
                        <Row
                          key={`post-row-${i}`}
                          flexDirection={['column', 'column', 'row']}
                          mt={[32]}
                          maxWidth={['100%', 344, '100%']}
                          width={['100%']}
                        >
                          {posts.slice(1, posts.length).slice(i === 0 ? 0 : i * 1, 1 + i * 1).map((post, x) => {
                            const conteudo = () =>{
                              return(
                                <div dangerouslySetInnerHTML={{
                                  __html: normalizeExcerpt (post.content)
                                }} />
                              )
                            }
                            return (
                              <Fragment>
                                <Column
                                  key={`post-item-${x + i}`}
                                  width={[1]}
                                >
                                  <Article
                                    title={new DOMParser().parseFromString(post.title,'text/html').childNodes[0].textContent}
                                    slug={post.slug}
                                    content={conteudo()}
                                    meta={{
                                      date: post.date,
                                      duration: '6 min'
                                    }}
                                    img={post.featured_media && post.featured_media.localFile.publicURL}
                                  />
                                </Column>
                              </Fragment>
                          )})}
                        </Row>
                      )
                  })}
                  {postLimit < Math.ceil(posts.length / 2) && (
                    <div
                      css={btnMedium_}
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        marginTop: 50
                      }}
                      onClick={() => {
                        setPostLimit(postLimit + 2)
                      }}
                    >
                      carregar mais
                    </div>
                  )}
                </Column>
              </Row>
            </Div>
          </>
        ) : (
          <p style={{ minHeight: '200px', display: 'flex', alignItems: 'center' }}>nenhum post</p>
        )}
      </Div>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
}

const StaticPage = ({data}) => {

  return (
    <Layout>
      <Seo 
        title='Blog | HostDime Brasil' 
        description='Artigos exclusivos que unem o universo técnico a soluções empreendedoras e inovadoras. Acesse para insights, guias de otimização e dicas para melhor uso da sua nuvem.' 
      />
      <PageTemplate
        title='Blog'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...PostListFields
      }
    }
    image1: file(relativePath: { eq: "blog.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
