import React from 'react'
import { css } from '@emotion/core'
import ArrowRight from '@material-ui/icons/ArrowForward'

import { mq } from './styles/Layout.styles'
import Overline from './Overline'
import Div from './Div/Div.styles'
import H6 from './H6'
import ds from '../theme'
import Hr from '../components/Hr'
import { Link } from 'gatsby'
import { normalizeDate } from '../utils/helpers'
import { postSummary, readMoreButton } from '../components/styles/PostBlog.styles'

const Article = ({
  meta,
  title,
  slug,
  content,
  img
}) => {
  const normalizedDate = normalizeDate(meta.date)
  return (
    <div
      css={
        css(
          mq({
            '&:not(:last-child)': {
              marginBottom: [24]
            } 
          })
        )
      }
    >
      <Div
        flexDirection='column'
        alignItems='end'

      >
        <H6
          fontSize={[33.6]}
          style={{
            fontWeight: 'normal',
            color: '#44474e',
            letterSpacing: 0.25,
            marginBottom: 16
          }}
        >
          <Link
            style={{
              color: 'inherit'
            }}
            to={`/blog/${slug}`}
          >
            {title}
          </Link>
        </H6>
        <Overline
          selected
          on='onSurface'
          variant='mediumEmphasis'
          style={{
            marginBottom: 32
          }}
        >
          {normalizedDate}
          {' '}
          •
          {' '}
          Leituras {meta.duration}
        </Overline>
        <Div
          style={{
            borderRadius: 6,
            width: '100%',
            height: 329,
            flexShrink: 0,
            background: `url(${img}) center center no-repeat`,
            backgroundSize: 'cover',
            ...img
            ? {
              background: `url(${img}) center center no-repeat`,
            }
            : {
              background: ds.brand('iceBlue'),
            },
          }}
        />
        <Div
          css={postSummary}
          style={{
            marginTop: 24
          }}
        >
          {content}
        </Div>
        <Link
          to={`/blog/${slug}`}
          css={readMoreButton}
          style={{
            marginBottom: 40
          }}
        >
          Continue lendo
          <ArrowRight 
            style={{
              color: '##9013fe',
              marginLeft: 8,
              width: 12
            }}
          />
        </Link>
        <Hr
          lineColor='lightPeriwinkle'
          maxWidth={['100%', 422, 680, 680]}
        />
      </Div>
    </div>
  )
}

export default Article
